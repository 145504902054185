<template>
    <div>
        <Message :closable="false" class="message-bus" :sticky="'sticky'" severity="warn" v-if="$store.getters.showShutdownDialog && $store.getters.vmStatus === 'running'">
            <div class="message-wrapper">
                <span>
                    Simubox will turn off <b> {{ $store.getters.vmShutdownTimeTo }} </b>. Go to settings to extend your session or
                </span>
    
                <Button :loading="extending_session" @click="extendSession()" label="Click here to extend"> </Button>
            </div>
        </Message>
        <FootBar />
        <div>
          <LogoBar />
          <NavBar ref="navbar1" />
        <!-- <hr class="hr_nav"> -->
        </div>
        <router-view />
    </div>
</template>

<script>
    import LogoBar from '@/components/navbar/LogoBar.vue'
    import NavBar from '@/components/navbar/NavBar.vue'
    import FootBar from '@/components/footbar/FootBar.vue'
    import Button from 'primevue/button';
    import Message from 'primevue/message';
    
    export default {
        components: {
            LogoBar,
            NavBar,
            FootBar,
            Button,
            Message
        },
        data() {
            return {
                simubox_fetch_interval: null,
                extending_session: false
            }
        },
        async mounted() {
            this.$store.dispatch('listOfSimulations')
            this.$store.dispatch('getSignalNames')
            this.$store.dispatch('getListOfSequences')
            setInterval(async () => {
                this.$store.dispatch('refreshIdToken')
            }, 60000)
            await this.$store.dispatch('getPreferences')
            await this.$store.dispatch('getVmUid')
        },
        methods: {
            async extendSession() {
                this.extending_session = true
                await this.$store.dispatch('extendSession')
                this.extending_session = false
            },
        },

    }
</script>

<style scoped>

.message-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center !important;
}
.message-bus {
    position: fixed;
    bottom: 1rem;
    left: 0;
    width: 100%;
    z-index: 999;
}
</style>