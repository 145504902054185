<template>
    <div class="footbar-container">

        <div>
            <div class="footbar-item">
                <it-icon outlined v-tooltip="'Version'" name="info" />
                <div> {{ $store.getters.clientVersion }} </div>
            </div>
            <it-divider vertical />
            <div class="footbar-item">
                <it-icon outlined v-tooltip="'User'" name="badge" />
                <div :class="isEmailVerified ? 'email-verified' : 'email-unverified'"> {{ $store.getters.user.data.email }} </div>
                <i v-if="isEmailVerified" class="pi pi-check-circle email-verified" v-tooltip.top.click="'Email verified'"></i>
                <i @click="sendEmailVerification()" v-else class="pi pi-times-circle email-unverified" v-tooltip.top.click="'Click here to send verification email'"></i>
            </div>
        </div>
        <div>
            <div class="footbar-item">
                <it-icon outlined v-tooltip="'Running model'" name="lan" />
                <div> {{ $store.getters.connectedModel }} </div>
            </div>
            <it-divider vertical />
            <div class="footbar-item">
                <it-icon v-tooltip="'Latency'" name="speed" />
                <div class="latency"> {{ $store.getters.connectionLatency }} </div>
            </div>
            <it-divider vertical />
            <div class="footbar-item">
                <it-icon v-tooltip="'Refresh ratio'" name="sync" />
                <div> {{ $store.getters.selectedRefreshRatio }} </div>
            </div>
            <it-divider vertical />
            <div class="footbar-item">
                <it-icon outlined v-tooltip="'Simulation time'" name="timer" />
                <div v-if="connectStatus.status === 'Connected'" class="simulation-time"> {{ calculatedSimuTime }} </div>
            </div>
            <it-divider vertical />
            <div class="footbar-item">
                <it-icon :style="{color: connectStatus.color}" v-tooltip="'Simulation state'" name="adjust" />
                <div> {{connectStatus.status}} </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    data() {
        return {
            
        }
    },
    methods: {
        async sendEmailVerification() {
            try {
                await this.$store.dispatch('verifyEmail')
                this.$toast.add({severity:'success', summary: 'Success!', detail: `Email sent correctly`, life: 5000}); 
            } catch (error) {
                console.log(error.message)
            }
        }
    },
    computed: {
        connectStatus() {
            switch (this.$store.getters.connectionStatus) {
                case 1:
                    return {status: 'Connecting', color: 'yellow'}
                case 2:
                    return {status: 'Connected', color: 'green'}
                case 3:
                    return {status: 'Failed', color: 'red'}
                case 4:
                    return {status: 'Reconnecting', color: 'yellow'}
            
                default:
                    return {status: 'Disconnected', color: 'red'};
            }
        },
        calculatedSimuTime() {
            if (this.$store.getters.simuTime.length > 0) {
                const LTime = this.$store.getters.simuTime[0].value
                const MTime = this.$store.getters.simuTime[1].value

                //20166.160s
                const seconds = ((MTime * 65536 + LTime) * 0.005).toFixed(3)
                const minutes = Math.floor(seconds/60)
                const milisecondsToShow = (seconds - Math.floor(seconds)).toFixed(3)
                const secondsToShow = Math.floor(seconds) % 60
                const minutesToShow = minutes % 60
                const hoursToShow = Math.floor(minutes/60)
                 


                return `${hoursToShow ? hoursToShow + 'h' : ''} ${minutesToShow ? minutesToShow + 'm' : ''} ${secondsToShow}s ${milisecondsToShow}ms`
            } else {
                return 0
            }
        },
        isEmailVerified() {
            return this.$store.getters.user.data ? this.$store.getters.user.data.emailVerified : false
        }
    }
}


</script>

<style scoped>
.footbar-container {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 0.8rem;
    width: 100%;
    height: 2rem;
    background-color: var(--color1);
    color: var(--font1);
    z-index: 999;
    padding: 0.5rem;
    -webkit-box-shadow: 3px -11px 27px -20px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px -11px 27px -20px rgba(66, 68, 90, 1);
    box-shadow: 3px -11px 27px -20px rgba(66, 68, 90, 1);
}
.footbar-container > * {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    align-items: center;
}
.footbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

}
.email-verified {
    color: var(--green1)
}
.email-unverified {
    color: var(--red1)
}
.email-unverified:hover {
    cursor: pointer;
}
.simulation-time {
    display: flex;
    justify-content: center;
    min-width: 8rem;
}
.latency {
    display: flex;
    justify-content: center;
    min-width: 4rem;
}
@media (max-width: 600px) {
    .footbar-container {
        display: none;
    }
}
</style>